<template>
  <div :class="`home_${$store.state.mode}`">
    <template v-if="$store.state.mode == 'pc'">
      <h2 class="title">个人中心</h2>
      <div class="home_top">
        <div class="top_left">
          <div class="user">
            <div class="photo" @click="handleavatar"><img :src="obj.avatar" alt="" /></div>
            <div class="info">
              <h3 class="name">
                欢迎！<span>{{ obj.nickName }}</span>
              </h3>
              <div class="user_phone">
                <span>手机号：{{ obj.userName }}</span>
                <span>ID：{{ obj.userId }}</span>
              </div>
              <div class="property">
                <div class="amount">
                  <p>金币</p>
                  <price :price="obj.accountAmount" />
                </div>
                <div class="credits">
                  <p>积分</p>
                  <price :price="obj.accountCredits" :type="2" />
                </div>
                <div class="recharge" @click="handlerecharge">
                  <i class="el-icon-s-finance"></i>
                  <span>充值</span>
                </div>
              </div>
            </div>
          </div>
          <div class="return" @click="handleLoginOut">退出登录</div>
        </div>
        <div class="line"></div>
        <div class="top_right">
          <div class="tip" v-if="obj.transactionLink">
            开箱前请验证您的交易邀请网址工作正常，您的库存设为 "公开"。您不应该禁止使用VAC。<br />
            <span @click="handlebindingsteam(0)">点击这里>></span>查找您的 STEAM 交易网址
          </div>
          <div class="steam_box" v-else>
            <p>绑定STEAM交易链接</p>
            <div class="steam">
              <input type="text" placeholder="请输入您的steam链接" v-model="transactionLink" />
              <button @click="handlebindingsteam(2)">提交</button>
            </div>
          </div>
        </div>
      </div>
      <div class="home_bottom">
        <el-tabs v-model="curTab" @tab-click="handleTab" class="tab_list">
          <el-tab-pane v-for="item in tabs" :key="item.type" :name="item.type">
            <span slot="label"><i :class="item.icon"></i> {{ item.name }}</span>
          </el-tab-pane>
        </el-tabs>
        <div class="user_info" v-if="curTab == 1">
          <div class="info_left">
            <div class="info_item" v-for="item in formList.slice(0, 4)" :key="item.key">
              <h3 v-if="item.title">{{ item.title }}</h3>
              <div class="line" v-if="item.title"></div>
              <h4 v-if="item.text">{{ item.text }}</h4>
              <h5>
                <input type="text" :value="userForm[item.key]" @input="handleInput($event, item.key)" :placeholder="item.placeholder" />
                <button v-if="!['oldPassword', 'password'].includes(item.key)" @click="handleSubmit(item)">提交</button>
              </h5>
              <p v-show="item.key == 'password' && (userForm[item.key].length < 5 || userForm[item.key].length > 20)">密码长度必须在5到20个字符之间</p>
            </div>
          </div>
          <div class="space"></div>
          <div class="info_right">
            <div class="info_item" v-for="item in formList.slice(4)" :key="item.key">
              <h3 v-if="item.title">{{ item.title }}</h3>
              <div class="line" v-if="item.title"></div>
              <h4>{{ item.text }}</h4>
              <h5 v-if="item.key == 'code'">
                <input type="text" :disabled="obj.parentInvitationCode" :value="userForm[item.key]" @input="handleInput($event, item.key)" :placeholder="item.placeholder" />
                <button v-if="!obj.parentInvitationCode" @click="handleSubmit(item)">提交</button>
              </h5>
              <h5 v-else><input type="text" :value="userForm[item.key]" @input="handleInput($event, item.key)" :placeholder="item.placeholder" /><button v-if="item.key == 'realName'" @click="handleconfirmname">认证</button></h5>
            </div>
          </div>
        </div>
        <knapsack v-if="curTab == 2" />
        <personal v-if="curTab == 4 || curTab == 5" :tabType="curTab" />
      </div>
    </template>
    <template v-else>
      <div class="header">
        <div class="header_img" @click="handleavatar">
          <img v-if="obj.avatar" :src="obj.avatar" alt="" />
          <i v-else class="el-icon-s-custom"></i>
        </div>
        <div class="nick_name" v-if="obj.userId">
          <div class="flex-center">
            <span>{{ obj.nickName }}</span>
            <b class="lv center">Lv{{ obj.vipLevel }}</b>
          </div>
          <div class="user_phone">
            <span>手机号：{{ obj.userName }}</span>
            <span>ID：{{ obj.userId }}</span>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="main_I space_between">
          <div class="main_I_I">
            账户余额
            <div class="left">
              <price :price="obj.accountAmount || 0" size="small" style="margin-right: 20px"></price>
              <price :type="2" :price="obj.accountCredits || 0" size="small"></price>
            </div>
          </div>
          <div class="main_I_II center btn_click" @click="handlerecharge">充值</div>
        </div>
        <div class="main_II space_around">
          <div class="main_II_I column btn_click" v-for="(item, index) in topMenus" :key="index" @click="handleMenu(index)">
            <div style="position: relative;">
              <img class="svg" :src="require(`../../assets/svgs/${item.svg}.svg`)" alt="" />
              <span class="dot" v-if="$store.state.noReadMsg && item.name == '我的消息'"></span>
            </div>
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="main_III">
          <div class="main_III_I" v-for="item in bottomMenus" :key="item.type" @click="handleHelp(item.type)">
            <img class="icon" :src="require(`../../assets/svgs/${item.svg}.svg`)" alt="" />
            <span class="text">{{ item.name }}</span>
            <img class="icon arrow" src="../../assets/svgs/arrow_right.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="bottom center" v-if="obj.userId" @click="handleLoginOut">退出登录</div>
      <alert class="alert nickname_alert" :visible="changeNickname" title="昵称修改" @cancel="handleCancel" @confirm="handleSubmit(formList[0])">
        <input type="text" v-model="userForm.nickName" placeholder="请输入您的昵称" />
      </alert>
      <alert class="alert authentication_alert" :visible="authentication" title="实名认证" @cancel="handleCancel" @confirm="handleconfirmname">
        <input type="text" v-model="userForm.realName" placeholder="请输入您的真实姓名" />
        <input type="text" v-model="userForm.idNum" placeholder="请输入您的身份证号码" />
      </alert>
      <alert class="alert password_alert" :visible="changepassword" title="密码修改" @cancel="handleCancel" @confirm="handlechangepwd">
        <input type="password" v-model="userForm.oldPassword" placeholder="请输入旧密码" />
        <input type="password" v-model="userForm.password" placeholder="请输入新的密码" />
        <p style="color: #868788; font-size: 12px; width: 100%; text-align: center; line-height: 2" :style="{ opacity: userForm.password.length < 5 ? 1 : 0 }">密码长度必须在5到20个字符之间</p>
        <input type="password" v-model="userForm.password1" placeholder="请输入确认密码" />
        <template v-if="userForm.password && userForm.password1">
          <p v-if="userForm.password === userForm.password1"></p>
          <p v-else style="color: #868788; font-size: 12px; width: 100%; text-align: center; line-height: 2">两次密码不一致</p>
        </template>
      </alert>
      <alert class="alert code_alert" :visible="changecode" title="绑定邀请码" @cancel="handleCancel" @confirm="handlechangecode">
        <input type="text" v-model="userForm.code" placeholder="请输入您的邀请码" />
        <p style="color: #868788; font-size: 12px; width: 100%; text-align: center; line-height: 2">邀请码仅可绑定一次，请谨慎操作</p>
      </alert>
    </template>
    <div v-if="curTab == 6" class="member_center">
      <headerTop v-show="$store.state.mode == 'mobile'" title="会员中心" />
      <div class="member_info">
        <div class="level">
          <span class="cont">
            <i class="el-icon-success"></i> 会员等级：<b>Lv{{ obj.vipLevel }}</b>
          </span>
          <span class="cumulative">
            累计充值：<span :style="{ color: $main }">{{ rechargeTotal }}</span>
          </span>
        </div>
        <div class="upgrade">
          <p>
            升级下一级还需要：<span :style="{ color: $main }">{{ needRechargePrice }}</span>
          </p>
          <div class="progress">
            <div class="bar" :style="{ width: (obj.vipLevel / levelList.length) * 100 + '%' }"></div>
          </div>
        </div>
      </div>
      <div class="member_upgrade">
        <h2>会员升级</h2>
        <div class="member_steps">
          <div class="steps_box" v-for="item in levelList" :key="item.id">
            <div class="steps">
              <p class="level_num">Lv{{ item.grade }}</p>
              <p class="level_price">充值{{ item.recharge }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="curTab == 7" class="promotion_center">
      <headerTop v-show="$store.state.mode == 'mobile'" title="推广中心" readme="推广说明" @handleReadme="promotionReadme" />
      <h2 class="pc-show">推广链接</h2>
      <div class="promotion_input">
        <div class="input">
          <h4>我的邀请码<span v-if="$store.state.mode == 'mobile'">：</span></h4>
          <h5><input type="text" :value="obj.invitationCode" :disabled="obj.invitationCode" /><button @click="handleCopy(obj.invitationCode)">复制</button></h5>
        </div>
        <div class="input">
          <h4>我的邀请链接<span v-if="$store.state.mode == 'mobile'">：</span></h4>
          <h5><input type="text" :value="inviteUrl" :disabled="inviteUrl" /><button @click="handleCopy(inviteUrl)">复制</button></h5>
        </div>
      </div>
      <div class="promotion_readme" @click="promotionReadme">
        <i class="el-icon-warning-outline"></i>
        <span>推广说明</span>
      </div>
      <div class="promotion_data">
        <div>
          <b>{{ ownBoxData.ownBoxNumber || 0 }}</b>
          <span>我的盒子数量</span>
        </div>
        <div>
          <b>{{ ownBoxData.totalOpenNumber || 0 }}</b>
          <span>累计打开次数</span>
        </div>
        <div>
          <b>{{ ownBoxData.totalPrize || '0.00' }}</b>
          <span>累计奖励</span>
        </div>
      </div>
      <div class="records">
        <h2 class="pc-show">推广记录</h2>
        <el-table v-loading="ownBoxLoading" rowKey="id" :data="ownBoxlist" class="table_list" style="width: 100%" :header-cell-style="{ textAlign: 'center' }" header-row-class-name="table_header" row-class-name="table_row" cell-class-name="table_cell promotion_cell">
          <el-table-column prop="boxId" label="盲盒ID" min-width="15%" />
          <el-table-column prop="boxName" label="盲盒名称" :show-overflow-tooltip="true" min-width="34%" />
          <el-table-column prop="openNum" label="开启次数" min-width="18%" />
          <el-table-column prop="price" label="奖励" min-width="15%" />
          <el-table-column prop="status" label="盲盒状态" min-width="18%">
            <template slot-scope="scope">
              <span :style="{ color: scope.row.status ? 'red' : $main }">{{ setBoxStatus(scope.row.status) }}</span>
            </template>
          </el-table-column>
          <div slot="empty">--暂无收益明细--</div>
        </el-table>
        <el-pagination background layout="prev, pager, next" :hide-on-single-page="true" :total="ownBoxTotal" :current-page="ownBoxPage.page" :page-size="ownBoxPage.size" @current-change="queryOwnBoxCollect" />
      </div>
      <div class="promotion_data">
        <div>
          <b>{{ prData.fansNumber || 0 }}</b>
          <span>推广人数</span>
        </div>
        <div>
          <b>{{ prData.fansRechargeTotal || 0 }}</b>
          <span>累计充值</span>
        </div>
        <div>
          <b>{{ prData.fansRechargePrizeRatio || '0.00' }}</b>
          <span>推广奖励比例</span>
        </div>
        <div>
          <b>{{ prData.historyTotal || '0.00' }}</b>
          <span>累计奖励</span>
        </div>
      </div>
      <div class="records">
        <h2 class="pc-show">推广记录</h2>
        <el-table v-loading="prlistLoading" rowKey="id" :data="prlist" class="table_list" style="width: 100%" :header-cell-style="{ textAlign: 'center' }" header-row-class-name="table_header" row-class-name="table_row" cell-class-name="table_cell promotion_cell">
          <el-table-column prop="userId" label="用户ID" />
          <el-table-column prop="nickName" label="用户名称" />
          <el-table-column prop="rechargeTotal " label="充值" />
          <el-table-column prop="fansRechargePrize" label="奖励" />
          <div slot="empty">--暂无收益明细--</div>
        </el-table>
        <el-pagination background layout="prev, pager, next" :hide-on-single-page="true" :total="prlistTotal" :current-page="prlistPage.page" :page-size="prlistPage.size" @current-change="queryFansRechargePrizeRecords" />
      </div>
    </div>
    <alert class="alert bindsteam_alert" :visible="isbindsteam" :hideCancel="Boolean(obj.transactionLink)" :title="obj.transactionLink ? '交易链接' : '绑定STEAM交易链接'" @cancel="handlebindingsteam(1)" @confirm="obj.transactionLink ? handleCopy(obj.transactionLink) : handlebindingsteam(2)" :confirmText="obj.transactionLink && '复制'">
      <div v-if="obj.transactionLink" class="msg" style="text-align: center">{{ obj.transactionLink }}</div>
      <input v-else v-model="transactionLink" type="text" placeholder="请输入STEAM交易链接" />
      <div class="msg">(只能绑定一次，如需更换请联系客服)</div>
    </alert>
    <el-dialog title="选择头像" :modal-append-to-body="false" append-to-body class="photo_dialog" :visible.sync="isAvatar" :close-on-click-modal="false" @close="closePhotoDialog">
      <div class="avatar_wrap">
        <div v-for="item in avatarList" :key="item.id" class="avatar_item" @click="handleavatarsass(item)">
          <img :src="item.url" alt width="100%" />
        </div>
      </div>
      <div class="close_btn" @click="closePhotoDialog">取消</div>
    </el-dialog>
  </div>
</template>

<script>
import { getUserInfo, updateUserDetails, realNameAuthentication, logOut, webResourceList, changePW, bindBoss, getGradeNorm, getDataOfRecharge, ownBoxCollect, fansRechargePrizeRecords } from '@/api/index';
import { removeToken } from '@/api/cookie';
import knapsack from './common/knapsack';
import personal from './common/personal';

export default {
  data() {
    return {
      isAvatar: false,
      changecode: false,
      onsubmit: true,
      onbindsteam: true,
      isbindsteam: false,
      transactionLink: '',
      changeNickname: false,
      changepassword: false,
      authentication: false,
      obj: {},
      avatarList: [],
      userForm: {
        idNum: '',
        realName: '',
        password: '',
        password1: '',
        oldPassword: '',
        nickName: '',
        // phoneNumber: '',
        code: '',
      },
      formList: [
        { title: '用户昵称', text: '修改用户昵称', key: 'nickName', placeholder: '请输入您的昵称' },
        // { title: '手机账户', text: '修改手机账户', key: 'phoneNumber', placeholder: '请输入您的手机号' },
        { title: '登录密码', text: '修改登录密码', key: 'oldPassword', placeholder: '请输入您的旧密码' },
        { title: '', text: '', key: 'password', placeholder: '请输入您的新密码' },
        { title: '', text: '', key: 'password1', placeholder: '请输入您的确认密码' },
        { title: '邀请码', text: '绑定邀请码', key: 'code', placeholder: '请绑定您的邀请码' },
        { title: '实名认证', text: '身份证号', key: 'idNum', placeholder: '请输入您的身份证号' },
        { title: '', text: '姓名', key: 'realName', placeholder: '请输入您的姓名' },
      ],
      curTab: '1',
      tabs: [
        { name: '账户信息', icon: 'el-icon-user-solid', type: '1' },
        { name: '物品背包', icon: 'el-icon-s-cooperation', type: '2' },
        // { name: '仓库', icon: 'el-icon-s-home', type: '3' },
        { name: '提货信息', icon: 'el-icon-s-order', type: '4' },
        { name: '充值明细', icon: 'el-icon-s-claim', type: '5' },
        // { name: '会员福利', icon: 'el-icon-star-on', type: '6' },
        { name: '推广中心', icon: 'el-icon-share', type: '7' },
      ],
      topMenus: [
        { name: '昵称修改', svg: 'change' },
        { name: '密码修改', svg: 'pwd' },
        { name: '实名认证', svg: 'renzheng' },
        { name: '个人明细', svg: 'mingxi' },
        { name: 'STEAM绑定', svg: 'bangding' },
        { name: '提货信息', svg: 'shopcar' },
        { name: '会员福利', svg: 'vip' },
        { name: '我的消息', svg: 'message' },
      ],
      bottomMenus: [
        { name: '隐私协议&用户协议', svg: 'xieyi', type: 2 },
        { name: '帮助中心', svg: 'bzzx', type: 4 },
        { name: '关于我们', svg: 'gywm', type: 3 },
      ],
      knapsackType: 1,
      // inviteUrl: `http://192.168.1.74:8080/login?code=${this.$store.state.USER_INFO.invitationCode}`,
      inviteUrl: `http://www.500csgo.com/login?code=${this.$store.state.USER_INFO.invitationCode}`,
      prData: {},
      prlist: [],
      prlistTotal: 0,
      prlistLoading: false,
      prlistPage: { page: 1, size: 10 },
      ownBoxData: {},
      ownBoxlist: [],
      ownBoxTotal: 0,
      ownBoxLoading: false,
      ownBoxPage: { page: 1, size: 10 },
      levelList: [],
      rechargeTotal: 0,
    };
  },
  components: { knapsack, personal },
  watch: {
    '$route.query': {
      handler(val) {
        this.curTab = val.type || '1';
        if (val.type) {
          if (this.curTab == '6') this.queryGradeNorm();
          if (this.curTab == '7') {
            this.queryOwnBoxCollect(1);
            this.queryFansRechargePrizeRecords(1);
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getInfo();
    this.queryDataOfRecharge();
    this.curTab = this.$route.query?.type || '1';
  },
  computed: {
    needRechargePrice() {
      if (!this.levelList.length) return 0;
      const needPrice = this.levelList.find(item => this.rechargeTotal < item.recharge);
      return needPrice.recharge - this.rechargeTotal;
    },
  },
  methods: {
    handleMenu(type) {
      console.log(type);

      if (type == 6) {
        // console.log(111);
        this.$router.push('/hongbao');
        return;
      }
      if (this.$store.state.LOGIN_IS_SHOW) {
        this.$router.push('/login');
        return;
      }
      if (type == 4) {
        this.handlebindingsteam(0);
        return;
      }
      this.playAudio();
      const opt = { 3: '/personal', 5: '/personal?delivery=1', 6: '/home?type=6', 7: '/notifications' };
      if (Object.keys(opt).includes(type.toString())) {
        this.$router.push(opt[type]);
        return;
      }
      const opt1 = { 0: 'changeNickname', 1: 'changepassword', 2: 'authentication' };
      if (Object.keys(opt1).includes(type.toString())) this[opt1[type]] = true;
    },
    setBoxStatus(status) {
      return { 0: '已上架', 1: '已下架', 2: '审核中' }[status];
    },
    queryGradeNorm() {
      getGradeNorm().then(res => {
        this.levelList = res?.data?.data || [];
      });
    },
    // 获取充值明细
    queryDataOfRecharge() {
      getDataOfRecharge().then(res => {
        this.rechargeTotal = res?.data?.data || 0;
      });
    },
    // 上面的推广记录
    queryOwnBoxCollect(page) {
      this.ownBoxLoading = true;
      if (page) this.ownBoxPage.page = page;
      ownBoxCollect(this.ownBoxPage).then(res => {
        this.ownBoxData = res?.data?.data || {};
        this.ownBoxLoading = false;
        const { list = [], total } = res?.data?.data?.details || {};
        this.ownBoxlist = [...list];
        this.ownBoxTotal = total || 0;
      });
    },
    // 下面的推广记录
    queryFansRechargePrizeRecords(page) {
      this.prlistLoading = true;
      if (page) this.prlistPage.page = page;
      fansRechargePrizeRecords(this.prlistPage).then(res => {
        this.prData = res?.data?.data || {};
        this.prlistLoading = false;
        const { list = [], total } = res?.data?.data?.details;
        this.prlist = [...list];
        this.prlistTotal = total || 0;
      });
    },
    closePhotoDialog() {
      this.playAudio();
      this.isAvatar = false;
    },
    promotionReadme() {
      this.$bus.$emit('is_illustrate', true);
      let timer = setTimeout(() => {
        this.$bus.$emit('nr_illustrate', { cont: 'promotion', title: '推广说明' });
        clearTimeout(timer);
      }, 100);
      this.playAudio();
    },
    handleKnapsack(type) {
      this.knapsackType = type;
    },
    handleInput(e, key) {
      const val = e.target.value || '';
      if (val) this.userForm[key] = val;
    },
    handleTab(tab) {
      this.playAudio();
      this.$router.replace(`/home?type=${tab.name}`);
    },
    handleCopy(text) {
      this.playAudio();
      this.isbindsteam = false;
      this.$copyText(text || `http://www.mkcsgo.com/login?code=${this.$store.state.USER_INFO.invitationCode}`).then(() => {
        this.$message({
          message: '复制成功',
          type: 'success',
        });
      });
    },
    playAudio() {
      this.$store.commit('playAudio');
    },
    handleavatarsass(data) {
      this.playAudio();
      updateUserDetails({ avatar: data.url }).then(res => {
        if (res?.data?.code == 200) {
          this.$message({
            message: '修改成功',
            type: 'success',
          });
          this.isAvatar = false;
          this.getInfo();
        }
      });
    },
    handleavatar() {
      this.playAudio();
      if (this.$store.state.LOGIN_IS_SHOW) {
        this.$router.push('/login');
        return;
      }
      this.isAvatar = true;
      webResourceList({ type: 1, page: 1, size: 100 }).then(res => {
        this.avatarList = res?.data?.data?.list || [];
      });
    },
    handlechangecode() {
      this.playAudio();
      if (this.userForm.code == '') {
        this.$message({
          message: '请输入邀请码',
          type: 'warning',
        });
      } else {
        bindBoss({ parentInvitationCode: this.userForm.code }).then(res => {
          if (res?.data?.code == 200) {
            this.$message({
              message: '绑定邀请码成功',
              type: 'success',
            });
            this.handleCancel();
          } else if (res?.data?.code == 500) {
            this.$message({
              message: res.data.msg,
              type: 'success',
            });
          }
        });
      }
    },
    handleLoginOut() {
      this.playAudio();
      this.$confirm('确定要退出登录吗?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        cancelButtonClass: 'active_btn',
        center: true,
      })
        .then(() => {
          this.playAudio();
          logOut().then(res => {
            if (res?.data?.code == 200) {
              removeToken();
              this.$store.commit('LOGIN_IS_SHOW', true);
              this.$router.push('/login');
            }
          });
        })
        .catch(() => {
          this.playAudio();
        });
    },
    handlerecharge() {
      this.playAudio();
      this.$router.push('recharge');
    },
    handleHelp(type) {
      this.playAudio();
      if (this.$store.state.LOGIN_IS_SHOW) {
        this.$router.push('/login');
        return;
      }
      this.$router.push(`/help?type=${type}`);
    },
    handlechangepwd() {
      this.playAudio();
      if (this.userForm.password == '') {
        this.$message({
          message: '请输入密码',
          type: 'warning',
        });
      } else if (this.userForm.password == this.userForm.password1) {
        changePW({
          oldPassword: this.userForm.oldPassword,
          password: this.userForm.password,
          passwordAgain: this.userForm.password1,
        }).then(res => {
          console.log('changePW', res);
          if (res?.data?.code == 200) {
            this.changepassword = false;
            this.getUserInfo().then(res => {
              if (res?.data?.code == 200) {
                this.$store.commit('USER_INFO', res?.data?.data);
              }
            });
            this.$message({
              message: '密码修改成功，请重新登陆',
              type: 'success',
            });
          }
        });
      } else {
        this.$message({
          message: '两次密码输入不一致,请重新输入',
          type: 'warning',
        });
      }
    },
    handleCancel() {
      this.playAudio();
      this.userForm = {
        idNum: '',
        realName: '',
        password: '',
        password1: '',
        oldPassword: '',
        code: '',
        nickName: '',
        // phoneNumber: '',
      };
      this.authentication = false;
      this.changeNickname = false;
      this.changepassword = false;
      this.changecode = false;
      this.isAvatar = false;
    },
    handleconfirmname() {
      this.playAudio();
      this.onsubmit = false;
      realNameAuthentication(this.userForm).then(res => {
        if (res?.data?.code === 200) {
          this.authentication = false;
          this.transactionLink = '';
          this.onsubmit = true;
          this.$message({
            message: '实名认证成功',
            type: 'success',
          });
        } else {
          this.onsubmit = true;
          this.$message({
            message: res.data.msg,
            type: 'warning',
          });
        }
      });
    },
    handlebindingsteam(type) {
      this.playAudio();
      if (type == 1) {
        this.isbindsteam = false;
      } else if (type == 2) {
        if (this.transactionLink) {
          updateUserDetails({ transactionLink: this.transactionLink }).then(res => {
            if (res?.data?.code == 200) {
              this.isbindsteam = false;
              this.$message({
                message: 'STEAM交易链接绑定成功',
                type: 'success',
              });
              getUserInfo().then(res => {
                this.obj = res?.data?.data || {};
                this.$store.commit('USER_INFO', this.obj);
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: 'warning',
              });
            }
          });
        } else {
          this.$message.closeAll();
          this.$message({
            message: '请输入STEAM交易链接',
            type: 'warning',
          });
        }
      } else if (type == 0) {
        this.transactionLink = '';
        this.isbindsteam = true;
      }
    },
    getInfo() {
      getUserInfo().then(res => {
        this.obj = res?.data?.data || {};
        this.$store.commit('USER_INFO', this.obj);
        const { parentInvitationCode: code } = this.obj;
        if (code) this.userForm.code = code == '206604' ? 'mkcsgo' : code;
      });
    },
    handleSubmit({ key, placeholder }) {
      if (key == 'password1') {
        this.handlechangepwd();
        return;
      }
      if (key == 'code') {
        this.handlechangecode();
        return;
      }
      if (!this.userForm[key]) {
        this.$message({ message: placeholder, type: 'warning' });
        return;
      }
      const params = {};
      params[key] = this.userForm[key];
      // if (key == 'phoneNumber') params.code = 6627;
      updateUserDetails(params).then(res => {
        if (res?.data?.code == 200) {
          this.$message({ message: '修改成功', type: 'success' });
          this.changeNickname = false;
          this.getInfo();
        } else {
          this.$message({ message: res.data.msg, type: 'error' });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dot {
  top: 0px;
  right: -5px;
  width: 6px;
  height: 6px;
  position: absolute;
  border-radius: 50%;
  background: red;
  padding: 0 !important;
}
.home_pc {
  @font-face {
    font-family: ysb;
    src: url('../../assets/font/YouSheBiaoTiHei-2.ttf');
  }
  .title {
    .sc(30px, #fff);
    margin-top: 40px;
    text-align: center;
  }
  .home_top {
    .flex-a-center;
    padding: 20px;
    position: relative;
    border-radius: 10px;
    background: #1a2427;
    margin: 60px @pcSpace;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    .top_left {
      flex: 1;
      .user {
        .flex-a-center;
        .photo {
          .wh(100px, 100px);
          overflow: hidden;
          border-radius: 50%;
          margin-right: 30px;
          img {
            .wh(100%, 100%);
          }
        }
        .info {
          flex: 1;
          .name {
            .flex-a-center;
            font-size: 20px;
            span {
              color: @main;
            }
          }
          .user_phone {
            margin-top: 4px;
            .sc(14px, #fff);
            span {
              padding-right: 20px;
            }
          }
          .property {
            .flex-a-center;
            .amount,
            .credits {
              margin-right: 40px;
              & > p {
                text-align: center;
                .sc(12px, #B4B4B4);
              }
            }
            .recharge {
              .wh(190px, 54px);
              .flex-center;
              .sc(18px, #fff);
              line-height: 52px;
              margin-left: 10%;
              font-weight: bold;
              background: url(../../assets/image/loginbtn.png) no-repeat;
              background-size: 100% 100%;
              i {
                font-size: 20px;
              }
              span {
                padding-left: 4px;
              }
            }
          }
        }
      }
      .return {
        .wh(100px, 30px);
        font-size: 12px;
        margin-top: 10px;
        border-radius: 15px;
        line-height: 30px;
        text-align: center;
        border: 1px solid @main;
        background: rgba(@main, 0.2);
      }
    }
    .line {
      .abs-cl;
      .wh(1px, 100%);
      background: #273137;
    }
    .top_right {
      flex: 1;
      .flex-column-center;
      .steam_box {
        width: 80%;
        margin: 0 auto;
        p {
          font-size: 18px;
          margin-bottom: 12px;
        }
      }
      .steam {
        .flex-a-center;
        .wh(100%, 44px);
        border-radius: 10px;
        border: 1px solid #888888;
        border-right: none;
        input {
          flex: 1;
          border: none;
          .sc(16px, #fff);
          background: none;
          padding-left: 24px;
        }
        button {
          .sc(16px, #fff);
          .btn-style(140px, 44px);
          outline: none;
          border-radius: 10px;
          border: 1px solid @main;
          background: rgba(@main, 0.2);
        }
      }
      .tip {
        margin-top: 10px;
        line-height: 30px;
        text-align: center;
        .sc(14px, #CDCDCD);
        span {
          color: @main;
          padding-right: 10px;
        }
      }
    }
  }
}
.promotion_center,
.home_bottom {
  h2 {
    width: 160px;
    .sc(20px, #DADADA);
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #fff;
  }
  h4 {
    margin-top: 20px;
    .sc(14px, #DADADA);
    @media @max750 {
      margin-top: 10px;
      white-space: nowrap;
    }
  }
  h5 {
    .flex-a-center;
    .wh(100%, 44px);
    margin-top: 10px;
    border-radius: 10px;
    font-weight: normal;
    border: 1px solid #888888;
    @media @max750 {
      height: 30px;
      border: none;
      margin-top: 0;
    }
    input {
      flex: 1;
      border: none;
      .sc(16px, #fff);
      background: none;
      padding-left: 24px;
      @media @max750 {
        padding-left: 0;
        font-size: 14px;
      }
    }
    button {
      .sc(16px, #fff);
      .btn-style(140px, 44px);
      outline: none;
      border-radius: 10px;
      border: 1px solid @main;
      background: rgba(@main, 0.2);
      @media @max750 {
        .wh(60px, 28px);
        font-size: 12px;
        border-radius: 0;
        line-height: 28px;
        .btn-bg;
        .abs-ct;
        right: 0;
      }
    }
  }
}
.home_bottom {
  margin: 60px @pcSpace;
  .user_info {
    display: flex;
    .space {
      width: 10%;
    }
    .info_left,
    .info_right {
      flex: 1;
      margin-top: 30px;
      .info_item {
        & > h3 {
          margin-top: 40px;
          .sc(20px, #DADADA);
        }
        .line {
          .wh(160px, 1px);
          margin-top: 20px;
          background: #fff;
        }
        & > p {
          margin: 10px;
          .sc(14px, #DADADA);
        }
      }
    }
  }
}
.home_mobile {
  margin: 0 14px;
  .header {
    width: 100%;
    font-size: 10px;
    margin: 12px 0;
    position: relative;
    .flex-column-center;
    .header_img {
      .flex-center;
      .wh(70px, 70px);
      border-radius: 50%;
      border: 1px solid #888;
      img {
        .wh(100%, 100%);
        border-radius: 50%;
      }
      i {
        .sc(40px, #888);
      }
    }
    .nick_name {
      .sc(16px, #fff);
      font-weight: bold;
      text-align: center;
      margin-top: 10px;
      span {
        color: @main;
      }
    }
    .lv {
      padding: 2px 6px;
      background: linear-gradient(-90deg, #3a97e4 0%, #25f484 100%);
      border-radius: 4px;
      margin-left: 6px;
      opacity: 1;
      .sc(10px, #fff);
    }
    .user_phone {
      .flex-a-center;
      padding-top: 4px;
      text-align: center;
      span {
        .sc(14px, #fff);
        padding: 0 10px;
      }
    }
  }
  .main {
    width: 100%;
    font-size: 18px;
    .main_I {
      width: 100%;
      height: 70px;
      box-sizing: border-box;
      background: #101115;
      border-radius: 10px;
      opacity: 1;
      padding: 12px;
      .main_I_I {
        text-align: left;
        width: 70%;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 13px;
      }
      .main_I_II {
        font-size: 14px;
        font-weight: bold;
        .btn-style(90px, 30px);
        .btn-bg;
      }
    }
    .main_II {
      width: 100%;
      color: #fff;
      font-size: 12px;
      margin: 10px 0;
      padding: 14px 0;
      border-radius: 10px;
      background: #101115;
      .grid(4, 10px);
      .main_II_I {
        justify-content: space-between;
        position: relative;
        & > span {
          padding-top: 4px;
        }
        &:hover {
          color: #fff;
          transition: all 0.3s linear;
          svg {
            path {
              fill: #fff;
              transition: all 0.3s linear;
            }
          }
        }
        &:not(:hover) {
          color: #fff;
          transition: all 0.3s linear;
          svg {
            path {
              fill: #fff;
              transition: all 0.3s linear;
            }
          }
        }
      }
      .svg {
        width: 22px;
        height: 22px;
      }
    }
    .main_III {
      margin-top: 10px;
      width: 100%;
      position: relative;
      background: #101115;
      border-radius: 10px;
      overflow: hidden;
      font-size: 15px;
      font-weight: 400;
      color: #fff;
      .main_III_I {
        width: 100%;
        height: 46px;
        padding: 0 16px;
        font-size: 11px;
        box-sizing: border-box;
        border-bottom: 1px solid #323436;
        &:last-of-type {
          border-bottom: none;
        }
        .flex-a-center;
        .text {
          flex: 1;
          padding-left: 10px;
          .sc(14px, #fff);
        }
        .handle {
          color: #888;
        }
        &:hover {
          color: #fff;
          transition: all 0.3s ease-in;
          .icon {
            path {
              fill: #fff;
              transition: all 0.3s ease-in;
            }
          }
        }
        .icon {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 45px;
    margin: 12px 0;
    background: #323436;
    border-radius: 10px;
    opacity: 1;
    font-size: 13px;
    font-weight: 400;
    color: #fff;
  }
}
.member_center {
  margin: 0 @pcSpace;
  @media @max750 {
    left: 0;
    top: 60px;
    margin: 0;
    width: 100%;
    bottom: 66px;
    overflow-y: auto;
    position: absolute;
    padding: 0 14px 30px;
    box-sizing: border-box;
    background: #101115;
  }
  .member_info {
    background: @bg1;
    padding: 40px 30px;
    border-radius: 10px;
    margin: 70px 0 60px;
    border: 1px solid @fc2;
    background: url(../../assets/image/userbg.png) no-repeat;
    background-size: auto 100%;
    background-position: right top;
    @media @max750 {
      margin: 0;
      padding: 20px;
      background-position: 10% top;
    }
    .level {
      .flex-a-center;
      i {
        color: @main;
      }
      .cont {
        .sc(22px, #fff);
        @media @max750 {
          font-size: 16px;
        }
        b {
          color: @main;
          font-family: ysb !important;
        }
      }
      .cumulative {
        .sc(18px, #fff);
        padding-left: 20px;
        @media @max750 {
          font-size: 16px;
        }
      }
    }
    .upgrade {
      & > p {
        margin: 18px 0 26px;
      }
      .progress {
        .wh(640px, 8px);
        position: relative;
        box-shadow:
          0 -1px 0 0 rgba(0, 0, 0, 0.67),
          0 1px 0 0 rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        @media @max750 {
          .wh(100%, 6px);
          box-shadow:
            0 -1px 0 0 rgba(255, 255, 255, 0.2),
            0 1px 0 0 rgba(255, 255, 255, 0.4);
        }
        .bar {
          .abs-ct;
          left: 1px;
          height: 4px;
          background: @main;
          border-radius: 1.5px;
          @media @max750 {
            height: 3px;
          }
        }
      }
    }
  }
  .member_upgrade {
    & > h2 {
      width: 160px;
      .sc(20px, #DADADA);
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #fff;
      @media @max750 {
        width: 40%;
        font-size: 18px;
        margin: 20px 0 30px;
        padding-bottom: 10px;
      }
    }
    .member_steps {
      display: flex;
      position: relative;
      &::after {
        content: '';
        .abs-ctl;
        .wh(100%, 1.5px);
        background: @main;
      }
      @media @max750 {
        width: 80%;
        margin: 0 auto;
        display: block;
        .grid(2, 0);
        &::after {
          content: '';
          top: 0;
          .abs-cl;
          .wh(1.5px, 100%);
          background: @main;
        }
      }
      .place {
        height: 0;
        &::after {
          display: none;
        }
      }
      .steps_box {
        flex: 1;
        display: flex;
        position: relative;
        justify-content: center;
        &::after {
          content: '';
          .abs-ctl;
          .wh(9px, 9px);
          border-radius: 50%;
          background: @main;
          @media @max750 {
            .wh(8px, 8px);
          }
        }
        &:nth-child(even) {
          padding-top: 114px;
          @media @max750 {
            padding-top: 0;
            margin-top: 75px;
            padding-left: 10px;
            &::after {
              .abs-ct;
              left: -4px;
            }
          }
        }
        &:nth-child(odd) {
          @media @max750 {
            padding-right: 10px;
            &::after {
              right: -4px;
              top: 37.5px;
              left: initial;
              transform: none;
              position: absolute;
            }
          }
        }
      }
      .steps {
        .wh(100px, 75px);
        .flex-column-center;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        border: 1px solid @fc2;
        .level_num {
          color: @main;
          font-weight: bold;
          font-family: ysb !important;
        }
      }
    }
  }
}
.promotion_center {
  margin: 70px @pcSpace 90px;
  @media @max750 {
    left: 0;
    top: 60px;
    margin: 0;
    width: 100%;
    bottom: 66px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    padding: 0 14px 30px;
    box-sizing: border-box;
    background: #101115;
  }
  .promotion_input {
    .flex-a-center;
    justify-content: space-between;
    @media @max750 {
      flex-direction: column;
    }
    .input {
      width: 45%;
      h4 {
        margin-top: 0;
      }
      @media @max750 {
        width: 100%;
        position: relative;
        h4 {
          color: #888;
          font-weight: normal;
        }
      }
    }
  }
  .promotion_readme {
    .flex-a-center;
    .sc(14px, #888888);
    margin-top: 40px;
    justify-content: flex-end;
    @media @max750 {
      display: none;
    }
    span {
      padding-left: 4px;
    }
  }
  .promotion_data {
    .flex-a-center;
    .wh(100%, 140px);
    margin: 20px 0 40px;
    border-radius: 10px;
    border: 1px solid #606365;
    justify-content: space-around;
    background: url(../../assets/image/tuiguang.png) no-repeat;
    background-size: 100% 100%;
    @media @max750 {
      height: 80px;
      margin: 14px 0;
      background-size: 220% 100%;
      background-position: center center;
    }
    & > div {
      .sc(20px, #fff);
      .flex-column-center;
      @media @max750 {
        font-size: 14px;
      }
      & > b {
        .sc(30px, #fff);
        padding-bottom: 10px;
        @media @max750 {
          font-size: 20px;
          padding-bottom: 4px;
        }
      }
    }
  }
  .records {
    width: 100%;
  }
}
.td {
  position: relative;
  .tr {
    position: absolute;
    right: 0;
    top: 0;
    width: 7px;
    height: 7px;
    background: #c23dd4;
    border-radius: 50%;
  }
}

.photo_dialog {
  .flex-center;
  /deep/ .el-dialog {
    width: 1260px;
    background: @bg1;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 0 !important;
    border: 1px solid #404448;
    @media @max750 {
      margin: 0 20px;
    }
    .el-dialog__header {
      padding: 14px 0;
      text-align: center;
      background: #0d1214;
      .el-dialog__title {
        .sc(24px, #fff);
        @media @max750 {
          font-size: 18px;
        }
      }
      .el-dialog__headerbtn {
        top: 20px;
        right: 20px;
      }
    }
    .el-dialog__body {
      padding: 0;
      .avatar_wrap {
        .grid(6, 10px);
        padding: 20px;
        max-height: 50vh;
        overflow-y: auto;
        box-sizing: border-box;
        .avatar_item {
          margin: 0 auto;
          .wh(100px, 100px);
        }
        @media @max750 {
          .grid(4, 10px);
          .avatar_item {
            .wh(60px, 60px);
          }
        }
      }
    }
    .close_btn {
      .wh(140px, 40px);
      .sc(18px, #fff);
      text-align: center;
      margin: 40px auto;
      line-height: 40px;
      border-radius: 20px;
      background: #19373a;
      .btn-shadow;
      @media @max750 {
        font-size: 14px;
        .wh(100px, 30px);
        line-height: 30px;
        margin: 20px auto;
      }
    }
  }
}

.alert {
  ::v-deep .alert_content {
    input {
      z-index: 2;
      .wh(100%, 40px);
      .sc(16px, #fff);
      background: #323436;
      border-radius: 35px;
      text-align: center;
      margin-top: 20px;
      border: none;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
.password_alert {
  ::v-deep .alert_content {
    input {
      margin: 0;
      &:nth-child(2) {
        margin-top: 20px;
      }
    }
  }
}
.bindsteam_alert {
  ::v-deep .alert_content {
    .msg {
      .sc(18px, #fff);
      margin: 10px 0;
      text-align: center;
      @media @max750 {
        font-size: 16px;
      }
    }
  }
}
</style>

<style lang="less">
.table_list {
  overflow-x: scroll;
  .promotion_cell {
    padding: 12px 0 6px;
    .cell {
      width: 100%;
      text-align: center;
      display: inline-block;
      box-sizing: border-box;
    }
  }
}
</style>
